<template>
  <TitleWrapper
    :filter-option="true"
    title="TEACHERS"
    tooltip-title="TEACHERS"
    :display-breadcrumb="true"
    :displayed-column-options="csvFilterOptions"
    :already-selected-displayed-column="filtersPayload.displayedColumns"
    :count="filtersCount"
    @clear="clearFilters"
    @cancel="cancelHanlder"
    @applyFilter="emitFiltersPayload($event, invalid)"
  >
    <template v-slot:filterItems>
      <InputFieldWrapper layout-mode="true">
        <UiInput
          v-model.trim="filtersPayload.first_name"
          :class="$style.widthItems"
          class="flex-1"
          title="First Name"
          type="text"
          placeholder="SEARCH_BY_FNAME"
          rules="alpha_spaces"
          :filter="FILTERS_LIST.FIRST_NAME"
          @emitQuery="updateQuery"
        ></UiInput>
        <UiInput
          v-model.trim="filtersPayload.last_name"
          :class="$style.widthItems"
          class="flex-1"
          title="Last Name"
          type="text"
          placeholder="SEARCH_BY_LNAME"
          rules="alpha_spaces"
          :filter="FILTERS_LIST.LAST_NAME"
          @emitQuery="updateQuery"
        ></UiInput>
        <UiInput
          v-model.trim="filtersPayload.username"
          :class="$style.widthItems"
          class="flex-1"
          title="Username"
          type="text"
          placeholder="SEARCH_BY_UNAME"
          rules="alpha_spaces"
          :filter="FILTERS_LIST.USER_NAME"
          @emitQuery="updateQuery"
        ></UiInput>
        <UiInput
          v-model.trim="filtersPayload.email"
          class="flex-1"
          title="Email"
          placeholder="Email"
          :filter="FILTERS_LIST.EMAIL"
          @emitQuery="updateQuery"
        />
        <UiInput
          v-model.trim="filtersPayload.phone"
          title="Phone Number"
          placeholder="Phone Number"
          class="flex-1"
          :filter="FILTERS_LIST.PHONE"
          @emitQuery="updateQuery"
        />
        <UiSingleSelect
          v-model.trim="filtersPayload.login_enabled"
          title="STATUS"
          :options="LOGIN_ENABLED_STATUSES"
          :class="$style.widthItems"
          input-color="bg-white"
          class="flex-1"
          label="title"
          reduce="value"
          :filter="FILTERS_LIST.LOGIN_ENABLED_STATUS"
          @emitQuery="updateQuery"
        />
        <UiDatePicker
          v-model.trim="filtersPayload.join_date"
          class="flex-1"
          title="JOINING_DATE"
          :class="$style.widthItems"
          :filter="FILTERS_LIST.JOIN_DATE"
          picker="date"
          @emitQuery="updateQuery"
        />
        <UiSingleSelect
          v-model.trim="filtersPayload.gender"
          title="Gender"
          :options="GENDERS"
          :class="$style.widthItems"
          input-color="bg-white"
          class="flex-1"
          label="title"
          reduce="value"
          :filter="FILTERS_LIST.GENDER"
          @emitQuery="updateQuery"
        />
        <UiMultiSelect
          v-model.trim="filtersPayload.subject_ids"
          title="Teacher Subject"
          :options="subjects"
          reduce="id"
          label="title"
          class="flex-1 mb-5 sm:mb-0"
          :already-selected="selectedSubjects"
          input-color="bg-white"
          :search-through-api="true"
          :filter="FILTERS_LIST.SUBJECTS"
          @emitQuery="updateQuery"
        />
        <UiDatePicker
          v-model="filtersPayload.date_of_birth_after"
          title="DOB_GT"
          :class="$style.widthItems"
          class="flex-1"
          :filter="FILTERS_LIST.DATE_OF_BIRTH_GREATER_THAN"
          @change="birthDateAfter"
          @emitQuery="updateQuery"
        />
        <UiDatePicker
          v-model="filtersPayload.date_of_birth_before"
          title="DOB_LT"
          :class="$style.widthItems"
          class="flex-1"
          :filter="FILTERS_LIST.DATE_OF_BIRTH_LESS_THAN"
          @change="birthDateBefore"
          @emitQuery="updateQuery"
        />
        <UiInput
          v-model.trim="filtersPayload.address"
          class="flex-1"
          title="Address"
          type="text"
          placeholder="Address"
          :filter="FILTERS_LIST.ADDRESS"
          @emitQuery="updateQuery"
        />
        <UiInput
          v-model.trim="filtersPayload.religion"
          class="flex-1"
          title="Religion"
          type="text"
          placeholder="Religion"
          :filter="FILTERS_LIST.RELIGION"
          @emitQuery="updateQuery"
        />
      </InputFieldWrapper>
    </template>
  </TitleWrapper>
</template>

<script>
import UiInput from '@components/UiElements/UiInputBox.vue'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import { USER_CONSTANTS } from '@src/constants/general-constants'
import {
  formatObjectKeyValues,
  setObjectValuesToEmpty,
  removeEmptyKeysFromObject,
  objectDeepCopy,
  getCountFromObject,
} from '@utils/generalUtil'
import { FILTERS_LIST } from '@src/constants/filter-constants.js'
import UiMultiSelect from '@/src/components/UiElements/UiMultiSelect.vue'
import UiDatePicker from '@components/UiElements/UiDatePicker.vue'
import fileMixins from '@/src/mixins/file-mixins'
import filterMixins from '@/src/mixins/filter-mixins.js'
import { mapState, mapActions } from 'vuex'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  name: 'TeacherFilters',
  components: {
    UiInput,
    UiSingleSelect,
    TitleWrapper,
    InputFieldWrapper,
    UiDatePicker,
    UiMultiSelect,
  },
  mixins: [fileMixins, filterMixins],

  props: {
    csvFilterOptions: {
      type: Array,
      default: () => [],
    },
    alreadySelectedDisplayedColumns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      FILTERS_LIST,
      $where: {},
      queries: {},
      subjects: [],
      filtersCount: 0,
      forceRender: 0,
      rerenderParent: 0,
      appliedQueries: {},
      selectedSubjects: [],
      GENDERS: USER_CONSTANTS.GENDERS,
      LOGIN_ENABLED_STATUSES: USER_CONSTANTS.LOGIN_ENABLED_STATUSES,
      filtersPayload: {
        username: '',
        first_name: '',
        last_name: '',
        gender: '',
        subject_ids: '',
        email: '',
        phone: '',
        join_date: '',
        date_of_birth_before: '',
        date_of_birth_after: '',
        address: '',
        religion: '',
        login_enabled: '',
        displayedColumns: [],
      },
    }
  },

  computed: {
    ...mapState({
      campus: (state) => state.layout.currentCampusScope.id || null,
      section: (state) => state.layout.currentSectionScope || null,
    }),
  },
  watch: {
    campus: {
      handler() {
        this.clearFilters()
        this.getSubjects()
      },
    },
    section: {
      handler() {
        this.clearFilters()
        this.getSubjects()
      },
    },
  },
  mounted() {
    this.updateDisplayedColumnList()
    this.getSubjects()
  },
  methods: {
    getFiltersCount() {
      this.getSubjects()
      return getCountFromObject(this.filtersPayload, 'displayedColumns', 'boolean')
    },
    getSubjects() {
      let alreadySelectedSubjects = []
      this.classSubjects({ section_id: this.section?.id }).then((result) => {
        this.subjects = result.data.records
        this.subjects?.forEach((record) => {
          if (this.filtersPayload && this.filtersPayload.subject_ids.length) {
            this.filtersPayload.subject_ids.forEach((id) => {
              if (record.id === id) {
                alreadySelectedSubjects.push(record)
              }
            })
          }
          this.selectedSubjects = alreadySelectedSubjects
          this.forceRender++
        })
      })
    },
    ...mapActions('subjects', ['classSubjects']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>

<template>
  <TeacherFilters
    :csv-filter-options="displayedFilterOptions"
    :already-selected-displayed-columns="filtersData?.displayedColumns"
    @apply="applyFilters"
  />
  <div v-if="isLoading" class="mt-28"><Loader :content="true" /></div>
  <div v-else-if="!isLoading && noRecord">
    <NoRecordFound />
  </div>
  <TableWrapper v-else>
    <THead>
      <TRHead>
        <TH
          v-for="head in tableHeaders.filter((head) => !head.skipForDataTableHead)"
          v-show="
            showColumnInDataTable(filtersData, head.dataTableName, [
              'Name',
              'Phone Number',
              'Email',
            ])
          "
          :key="head.id"
        >
          {{ head.dataTableName }}
        </TH>
        <TH
          v-show="showColumnInDataTable(filtersData, 'Campus', ['Campus'])"
          v-if="!currentCampusScope"
        >
          Campus
        </TH>
        <TH
          v-show="showColumnInDataTable(filtersData, 'Class', ['Class'])"
          v-if="!currentClassScope"
        >
          Class
        </TH>
        <TH
          v-show="showColumnInDataTable(filtersData, 'Section', ['Section'])"
          v-if="!currentSectionScope"
        >
          Section
        </TH>
        <TH>Actions</TH>
      </TRHead>
    </THead>
    <TBody>
      <TRBody v-for="(teacher, index) in teacherList" :key="index">
        <TD v-show="showColumnInDataTable(filtersData, 'Name', ['Name'])">
          <span @click="redirectToTeacherMode(teacher)">
            <SingleUserDisplay :user="teacher" label="full_name" :image="true" />
          </span>
        </TD>

        <TD v-show="showColumnInDataTable(filtersData, 'Phone Number', ['Phone Number'])">
          <DisplayPhoneNumber :phone="teacher.phone" />
        </TD>
        <TD v-show="showColumnInDataTable(filtersData, 'Email', ['Email'])" :lowercase="true">
          {{ teacher.email || GENERAL_CONSTANTS.NOT_APPLICABLE }}
        </TD>
        <TD v-show="showColumnInDataTable(filtersData, 'Gender')">
          {{ teacher.gender || GENERAL_CONSTANTS.NOT_APPLICABLE }}
        </TD>
        <TD v-show="showColumnInDataTable(filtersData, 'Date Of Birth')">
          {{
            (teacher.profile && teacher.profile.date_of_birth) || GENERAL_CONSTANTS.NOT_APPLICABLE
          }}
        </TD>
        <TD v-show="showColumnInDataTable(filtersData, 'Join Date')">
          {{
            (teacher.professional_info && teacher.professional_info.join_date) ||
            GENERAL_CONSTANTS.NOT_APPLICABLE
          }}
        </TD>
        <TD v-show="showColumnInDataTable(filtersData, 'Blood Group')">
          {{ (teacher.profile && teacher.profile.blood_group) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
        </TD>
        <TD v-show="showColumnInDataTable(filtersData, 'Address')">
          {{ (teacher.profile && teacher.profile.address) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
        </TD>
        <TD v-show="showColumnInDataTable(filtersData, 'Religion')">
          {{ (teacher.profile && teacher.profile.religion) || GENERAL_CONSTANTS.NOT_APPLICABLE }}
        </TD>
        <TD v-show="showColumnInDataTable(filtersData, 'Assigned Subjects')">
          <BadgeDisplay
            v-if="teacher?.section_subject_teacher.length"
            :options="teacher.section_subject_teacher"
            label="subject_title"
            detail="section_title"
            class="px-8"
          />
          <span v-else>
            {{ GENERAL_CONSTANTS.NOT_APPLICABLE }}
          </span>
        </TD>
        <TD v-show="showColumnInDataTable(filtersData, 'Status')">
          <UiStatus
            :title="
              teacher.login_activated_at
                ? GENERAL_CONSTANTS.LOGIN_ENABLED
                : GENERAL_CONSTANTS.LOGIN_DISABLED
            "
            :success="!!teacher.login_activated_at"
          />
        </TD>
        <TD
          v-show="showColumnInDataTable(filtersData, 'Campus', ['Campus'])"
          v-if="!currentCampusScope"
        >
          <BadgeDisplay
            :options="teacher.users_roles_entities"
            :is-object-concated="false"
            :nested-label="['campus', 'title']"
          />
        </TD>

        <TD
          v-show="showColumnInDataTable(filtersData, 'Class', ['Class'])"
          v-if="!currentClassScope"
        >
          <BadgeDisplay
            :options="teacher.users_roles_entities"
            :is-object-concated="false"
            :nested-label="['class', 'title']"
          />
        </TD>
        <TD
          v-show="showColumnInDataTable(filtersData, 'Section', ['Section'])"
          v-if="!currentSectionScope"
        >
          <BadgeDisplay
            :options="teacher?.users_roles_entities"
            :nested-label="['section', 'title']"
            :is-object-concated="false"
          />
        </TD>
        <TD>
          <TableAction
            :current-user="teacher"
            :idx="index"
            :action-list="teacher?.actionsList"
            @action="teacherAction"
          />
        </TD>
      </TRBody>
    </TBody>
  </TableWrapper>
  <Pagination
    v-if="showPagination"
    v-show="!isLoading"
    :key="`Pagination${forceRender}`"
    :record-limit="filteredRecordLimit"
    :max-range="paginationCounts(teacherCounts, filteredRecordLimit)"
    @filterRecord="filterRecord"
  />

  <!-- Add & edit teacher modal -->
  <TeacherModal
    v-if="teacherAddModal"
    :modal="teacherAddModal"
    :edit="currentTeacher"
    @toggle="teacherModalToggle"
  ></TeacherModal>
  <!-- Teacher enrollment modal -->

  <TeacherEnrollmentModal
    v-if="showEnrollmentModal"
    :modal="showEnrollmentModal"
    :current-user="currentTeacher"
    @toggle="toggleTeacherEnrollmentModal"
  />

  <!-- Activate Teacher Modal -->
  <UserEnableModal @success="fetchData" />
  <UserDeactivateModal @success="fetchData" />
  <!-- Disable Modal -->
  <UserDisableModal @success="fetchData" />
  <!-- Reset Password -->
  <ResetPasswordModal @success="fetchData" />
  <RoleModal
    v-if="isRoleModal"
    :modal="isRoleModal"
    user-type="Teacher"
    assigning-role="section_teacher"
    @toggle="toggleRoleModal"
  />
  <UIConfirmationModal
    v-if="isSetupPassword"
    heading="User Setup Password Email"
    :message="GENERAL_CONSTANTS.emailSetupMessage"
    button-text="Sent"
    :modal="isSetupPassword"
    :name="currentTeacher?.full_name"
    remaining-message=""
    @cancel="toggleUserPasswordSetupConfirmationModal"
    @confirm="sentSetupPasswordEmailHandler"
  />
</template>

<script>
import { isEmpty } from 'lodash'
import TeacherEnrollmentModal from '@views/teacher/enrollment/TeacherEnrollmentModal.vue'
import Loader from '@components/BaseComponent/Loader.vue'
import TeacherModal from '@/src/router/views/admin/modals/TeacherModal.vue'
import { objectDeepCopy, showColumnInDataTable } from '@utils/generalUtil'
import TableAction from '@/src/components/TableAction.vue'
import { mapActions, mapState, mapGetters } from 'vuex'
import generalMixin from '@src/mixins/general-mixins.js'
import Pagination from '@components/BaseComponent/Pagination.vue'
import BadgeDisplay from '@src/components/UiElements/UiBadgeDisplay.vue'
import ResetPasswordModal from '@src/components/action-components/ResetPasswordModal.vue'
import {
  redirectToTeacherMode,
  getOptionsBasedOnUserStatus,
} from '@src/utils/settings/tenant-user.util.js'
import UserEnableModal from '@src/components/action-components/EnableLoginModal.vue'
import UserDeactivateModal from '@/src/components/action-components/DeactivateModal.vue'
import { currentUTCDateTimeWithZone } from '@src/utils/moment.util.js'
import TeacherFilters from '@views/teacher/TeacherFilters.vue'
import GENERAL_CONSTANTS, { SCOPE_LEVELS } from '@src/constants/general-constants'
import RoleModal from '@views/admin/modals/RolesModal.vue'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import DisplayPhoneNumber from '@src/components/UiElements/PhoneDisplay.vue'
import UserDisableModal from '@src/components/action-components/DisableModal.vue'
import fileMixins from '@/src/mixins/file-mixins'
import scrollMixin from '@src/mixins/scroll-mixin'
import NoRecordFound from '@/src/components/BaseComponent/NoRecordFound.vue'
import UIConfirmationModal from '@src/components/UiElements/UIConfirmationModal.vue'
import {
  teacherGraphChart,
  rightBarHeader,
  rightBarStats,
} from '@/src/router/views/teacher/teacher.util.js'
import teacherData from '@/src/router/views/teacher/teacher.json'
import UiStatus from '@src/components/UiElements/UiStatus.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'
export default {
  name: 'CampusTeacher',
  components: {
    SingleUserDisplay,
    UserEnableModal,
    Pagination,
    Loader,
    TeacherModal,
    TableAction,
    BadgeDisplay,
    TeacherEnrollmentModal,
    ResetPasswordModal,
    TeacherFilters,
    RoleModal,
    UserDeactivateModal,
    UIConfirmationModal,
    UserDisableModal,
    DisplayPhoneNumber,
    NoRecordFound,
    UiStatus,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [generalMixin, fileMixins, scrollMixin],
  page: teacherData.page,
  data() {
    return {
      forceRender: 0,
      GENERAL_CONSTANTS,
      attendanceTranslation: 'attendanceTranslation',
      title: 'title',
      alwaysDisplayColumnsForCSV: teacherData.alwaysDisplayColumnsForCSV,
      dashboard: 'dashboard',
      options: ['Select'],
      tableHeaders: teacherData.tableHeaders,
      defaultSelectedColumns: teacherData.defaultSelectedColumns,
      teacherList: [],
      isLoading: false,
      noRecord: false,
      imageModal: false,
      profilePic: '',
      selectedUser: '',
      previewImage: {
        name: '',
        url: null,
      },
      actionList: null,
      filtersData: {},
      currentTeacher: null,
      teacherCounts: '',
      showPagination: false,
      filteredRecordLimit: 10,
      teacherClasses: [],
      showEnrollmentModal: false,
      showBulkUpload: false,
      filterData: null,
      isRoleModal: false,
      classLevelPermissions: {
        basedOn: [SCOPE_LEVELS.CLASS_LEVEL],
      },
      isSetupPassword: false,
    }
  },
  computed: {
    ...mapState({
      enableLoginModal: (state) => state.layout.enableLoginModal,
      showDeactivateModal: (state) => state.layout.showDeactivateModal,
      showDisableModal: (state) => state.layout.showDisableModal,
      resetPassword: (state) => state.layout.resetPassword,
      tabRightBar: (state) => state.layout.tabRightNav,
      currentCampusScope: (state) => state.layout.currentCampusScope,
      currentSectionScope: (state) => state.layout.currentSectionScope,
      currentClassScope: (state) => state.layout.currentClassScope,
      teacherAddModal: (state) => state.layout.teacherAddModal,
      showUiModal: (state) => state.layout.showUiModal,
    }),
    modalValueChanges() {
      return (
        this.enableLoginModal ||
        this.showDeactivateModal ||
        this.showDisableModal ||
        this.resetPassword
      )
    },
    ...mapGetters('layout', ['isManualPasswordResetAllowed']),
  },
  watch: {
    modalValueChanges: {
      handler(value) {
        if (!value) this.currentTeacher = null
      },
    },
    tabRightBar: {
      immediate: true,
      handler(value) {
        if (value) this.fetchTeacherStats()
      },
    },
    currentCampusScope: {
      deep: true,
      handler() {
        this.filterRecord()
      },
    },
    currentSectionScope: {
      deep: true,
      handler() {
        this.filterRecord()
      },
    },
    currentClassScope: {
      deep: true,
      handler() {
        this.filterRecord()
      },
    },
    showUiModal: {
      handler(value) {
        if (value) this.toggleRoleModal()
      },
    },
  },
  mounted() {
    this.filterRecord()
    this.setRightBarData()
  },
  methods: {
    currentUTCDateTimeWithZone,
    redirectToTeacherMode,
    showColumnInDataTable,
    applyFilters(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.filterRecord()
      this.forceRender++
    },
    fetchTeacherStats(payload = { $where: {} }) {
      this.getTeacherStats(payload).then((res) => {
        this.setRightBarData(res)
      })
    },
    fetchData() {
      this.forceRender++
      this.filterRecord()
    },
    toggleRoleModal(payload) {
      this.isRoleModal = !this.isRoleModal
      if (!this.isRoleModal) this.$store.dispatch('layout/setShowUiModal', false)
      if (payload) {
        this.filterRecord()
        this.forceRender++
      }
    },
    /**
     * Create table action list dynamicaaly
     * @param {String} userStaus
     * @return {Array} actionsArray
     */

    tableActionList(userStatus) {
      this.actionList = [
        { name: 'TV_A' },
        {
          name: 'Edit',
          basedOn: [SCOPE_LEVELS.ROOT_LEVEL, SCOPE_LEVELS.CAMPUS_LEVEL, SCOPE_LEVELS.SECTION_LEVEL],
        },
        { name: 'TV_DEACTIVATE_USER' },
      ]

      let options = getOptionsBasedOnUserStatus(userStatus, this.isManualPasswordResetAllowed)
      this.actionList = [...this.actionList, ...options]

      return this.actionList
    },
    /**
     * Toggle teacher enrollment modal
     */
    toggleTeacherEnrollmentModal(payload) {
      this.showEnrollmentModal = !this.showEnrollmentModal
      if (payload) {
        this.filterRecord()
        this.forceRender++
      }
    },
    setRightBarData(stats = 0) {
      this.setRightbarContent({
        header: {
          title: 'Teachers',
          buttons: [
            {
              title: 'Add Teacher',
              classes: ['primary-button-right'],
              action: { name: 'layout/setTeacherAddModal' },
            },
            {
              title: 'ADD_EXISTING_USER',
              classes: ['primary-button-right'],
              action: { name: 'layout/setShowUiModal' },
            },
          ],
        },
        stats: rightBarStats(stats, this.currentCampusScope, this.currentSectionScope),
        chartData: teacherGraphChart(stats, this.currentCampusScope, this.currentSectionScope),
      })
    },
    filterRecord(range) {
      this.isLoading = true
      let payload = {
        skip: range?.skip || GENERAL_CONSTANTS.RECORD_SKIP,
        limit: range?.limit || GENERAL_CONSTANTS.RECORD_LIMIT,
      }
      payload = this.filtersData ? { ...payload, ...{ $where: this.filtersData?.$where } } : payload
      this.getTeacherWithFilters(payload)
        .then((res) => {
          this.teacherCounts = res?.data?.meta?.total_records
          this.showPagination = this.teacherCounts > GENERAL_CONSTANTS.RECORD_LIMIT
          if (res?.data?.records) {
            this.teacherList = objectDeepCopy(res.data.records)
          }
          this.teacherList?.forEach(
            (teacher) => (teacher.actionsList = this.tableActionList(teacher.login_activated_at)),
          )
          if (this.$router?.currentRoute?._rawValue?.path === '/teachers')
            this.fetchTeacherStats({ $where: this.filtersData?.$where })
          this.currentTeacher = null
        })
        .finally(() => {
          this.noRecord = isEmpty(this.teacherList)
          this.isLoading = false
        })
    },
    handleClick(eventName) {
      switch (eventName) {
        case 'close':
          this.imageModal = false
          this.filterRecord()
          this.$refs['img-upload-teacher'].value = null
          this.previewImage = {
            name: '',
            url: null,
          }
          break
        case 'cancel':
          break
        case 'save':
          break
      }
    },
    toggleConfirmationModal() {
      this.confirmation = !this.confirmation
      if (!this.confirmation) this.currentTeacher = null
    },

    imageModalToggle(teacher) {
      this.selectedUser = {
        name: `${teacher.first_name} ${teacher.last_name}`,
        id: teacher.id,
      }
      this.imageModal = !this.imageModal
    },
    uploadImage() {
      const input = this.$refs['img-upload-teacher']
      const file = input.files
      if (file && file[0]) {
        const reader = new FileReader()
        reader.onload = (e) => {
          this.previewImage.url = e.target.result
          this.previewImage.name = file[0].name
        }
        reader.readAsDataURL(file[0])
      }
    },
    teacherAction(action, current) {
      this.currentTeacher = current
      this.$store.commit('layout/SET_CURRENT_SELECTED_USER', this.currentTeacher)
      switch (action) {
        case 'Edit':
          this.teacherModalToggle()
          break
        case 'TV_A':
          this.toggleTeacherEnrollmentModal()
          break
        case 'TV_DEACTIVATE_USER':
          this.$store.commit('layout/SET_SHOW_DEACTIVATE_MODAL', true)
          break
        case 'TV_ACTIVATE_ACCOUNT':
          this.$store.commit('layout/ENABLE_LOGIN_MODAL', true)
          break
        case 'TV_SUSPEND_ACCOUNT':
          this.$store.commit('layout/SET_SHOW_DISABLE_MODAL', true)
          break
        case 'TV_RP':
          this.$store.commit('layout/RESET_PASSWORD_MODAL', true)
          break
        case 'TV_RE':
          this.toggleUserPasswordSetupConfirmationModal()
          break
      }
    },
    /**
     * Activate & Deactivate teacher
     * @param {string} status
     * @param {Object} currentUser
     */

    toggleUserPasswordSetupConfirmationModal() {
      this.isSetupPassword = !this.isSetupPassword
      if (!this.isSetupPassword) this.currentTeacher = null
    },

    async sentSetupPasswordEmailHandler() {
      let payload = { user_id: this.currentTeacher?.id }
      await this.resendUserSetupPassword(payload)
      this.toggleUserPasswordSetupConfirmationModal()
    },
    teacherModalToggle(payload) {
      this.setTeacherAddModal()
      if (payload) {
        this.filterRecord()
        this.forceRender++
      }
      if (!this.teacherAddModal) this.currentTeacher = null
    },
    /**
     * Deactivate teacher
     * @param {String} id - user id
     */

    ...mapActions('teacher', ['getTeacherStats', 'getTeacherWithFilters']),
    ...mapActions('layout', ['setRightbarContent', 'setRightBarStats', 'setTeacherAddModal']),
    ...mapActions('users', ['uploadUserImage']),
    ...mapActions('auth', ['resendUserSetupPassword']),
  },
}
</script>

import teacherData from '@/src/router/views/teacher/teacher.json'
import GRAPH_CONSTANTS from '@src/constants/graph-constants.js'
import GENERAL_CONSTANTS from '@src/constants/general-constants'

export const rightBarStats = (teacherStats, currentCampusScope, currentSectionScope) => {
  const stats = [
    {
      title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
      categories: [
        {
          text: 'TOTAL_TEACHER',
          value: teacherStats?.total_teachers || GENERAL_CONSTANTS.EMPTY_RECORD,
          tooltipDescription: 'Total No. of Teachers',
        },
        {
          text: 'ASSIGNED',
          value: teacherStats?.assigned_teachers || GENERAL_CONSTANTS.EMPTY_RECORD,
          width: 'w-full border-r-2',
          tooltipDescription:
            currentCampusScope && !currentSectionScope
              ? 'Total No. of Assigned Section Teachers'
              : currentCampusScope && currentSectionScope
              ? 'Total No. of Assigned Subject Teachers'
              : 'Total No. of Assigned Campus Teachers',
        },
        {
          text: 'UNASSIGNED',
          value: teacherStats?.unassigned_teachers || GENERAL_CONSTANTS.EMPTY_RECORD,
          tooltipDescription:
            currentCampusScope && !currentSectionScope
              ? 'Total No. of Unassigned Section Teachers'
              : currentCampusScope && currentSectionScope
              ? 'Total No. of Unassigned Subject Teachers'
              : 'Total No. of  Unassigned Campus Teachers',
        },
      ],
    },
  ]
  return stats
}
export const teacherGraphChart = (teacherStats) => {
  let PIE_GRAPH_VALUE = [
    Math.floor(teacherStats?.total_teachers - teacherStats?.login_enabled_count),
    Math.floor(teacherStats?.login_enabled_count),
  ]
  const chartData = [
    GRAPH_CONSTANTS.PIE_CHART(
      teacherData.PIE_LABELS,
      PIE_GRAPH_VALUE,
      teacherData.PIE_BACKGROUND_COLOR,
      teacherData.PIE_GRAPH_TITLE,
    ),
  ]
  return chartData
}
